import AgreementComponent from "./AgreementComponent";
import AwardComponent from "./AwardComponent";
import BonusPolicyComponent from "./BonusPolicyComponent";
import ContactComponent from "./ContactComponent";
import FAQComponent from "./FaqComponent";
import GamblingComponent from "./GamblingComponent";
import GameGuideComponent from "./GmeGuideComponent";
import RulesComponent from "./RulesComponent";
import DepositBonus from "./DepositBonus";
import { useEffect, useRef, useState } from "react";

const GeneralModalWrapper = ({ type, status, closePopup, ...restProps }) => {
  const modalContentRef = useRef(null);
  const [display, setDisplay] = useState("none");
  const [opacity, setOpacity] = useState(1);

  const handleModalClose = () => {
    // setTimeout(() => closePopup(), 1000);

    setDisplay("none");
    setOpacity(0);
    closePopup();
  };

  useEffect(() => {
    // modalContentRef.current.scrollTop = 0;
  }, [type]);

  useEffect(() => {
    // alert(status);
    if (type == "contact") restProps["data-bs-backdrop"] = "static";
    // if (status === "active") document.body.style.overflow = "hidden";
    // return () => (document.body.style.overflow = "scroll");
  }, [status]);

  useEffect(() => {
    if (status === "active") {
      setDisplay("block");
      setTimeout(() => setOpacity(5), 10);

      // setOpacity(1);
    }
  }, [status]);
  return (
    <>
    { status == "active" ? (
    <div className="modal showModal"  tabindex="-1" {...restProps} aria-labelledby="exampleModalLabel"
      style={{
        backgroundColor: "#000000a1",
      }}
    >
      <div className={"modal-dialog modal-dialog-scrollable  modal-lg showModalcontent"}>
        <div className="modal-content popupBg">
          <div className="modal-header">
            <button  type="button" className="btn-close"
              onClick={(e) => {
                e.preventDefault();
                handleModalClose();
                // closePopup();
              }}  data-bs-dismiss="modal" aria-label="Close" style={{ backgroundColor: "white" }} ></button>
          </div>
          <div className="modal-body" ref={modalContentRef}>
            {type === "contact" ? (
              <ContactComponent />
            ) : type === "rules" ? (
              <RulesComponent />
            ) : type === "DepositBonus" ? (
              <DepositBonus closePopup={closePopup} />
            ) : type === "agreement" ? (
              <AgreementComponent />
            ) : type === "guide" ? (
              <GameGuideComponent />
            ) : type === "faq" ? (
              <FAQComponent />
            ) : type === "bonus" ? (
              <BonusPolicyComponent />
            ) : type === "award" ? (
              <AwardComponent />
            ) : (
              <GamblingComponent />
            )}
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>) : null }
    </>
  );
};

export default GeneralModalWrapper;